<style type="text/css">
	.warnaKotak{
		background: #fe8c00;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #ffff00, #ffffff);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to right, #ffff00, #ffffff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}
	
	.warnaKotakSelesai{
		background: #fe8c00;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #ff0000, #ffffff);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to right, #ff0000, #ffffff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}
</style>
<template>
    <div class="w3-theme-l1 w3-padding" style="position: relative;" >
		<div class="w3-row">
			<div class="w3-col l9">
				<div v-if="loading.antrian">
					<DeLoading :show="true"></DeLoading>
				</div>
				<grid 
					:center="false"
					:draggable="true"
					:sortable="true"
					:items="recs"
					:cellHeight="180"
					:cellWidth="180"
					:rowCount="6"
					:gridWidth="200"
					@change="change"
					@remove="remove"
					
					@sort="sort"
					:style="'overflow-x: auto;height:' + (screenHeight-90)/2 + 'px;'"
					>
					<template slot="cell" scope="props" >
						<div class="w3-round-large w3-padding-small warnaKotak" style="height: 170px; width: 170px;float: left!important;position: relative;">
							<!-- <span>{{props.index}}</span> -->
							<div align="center" class="w3-xxxlarge w3-text-black">{{props.item.antrian_nomor}}</div>
							<div align="center" class="w3-xlarge w3-text-black">{{props.item.pelayanan_name}}</div>
							<div class="w3-center" style="position: absolute;bottom: 0px; padding: 5px; right: 0px; left: 0px;">
								<button style="width: 100%;" v-on:click="setPanggil(props.item, props.index)" class="w3-btn w3-theme-d5 w3-small w3-round" type="button">Panggil</button>
								<!-- <button style="margin-left: 5px;" class="w3-btn w3-theme-d3 w3-small" type="button">selesai</button> -->
							</div>
						</div>
					</template>
				</grid>
				<div class="w3-border w3-theme-l3 w3-padding w3-round-large">
					<div style="height: 40px;" class="w3-large w3-center"><b>Sudah dilayani</b></div>
					<grid
						:center="false"
						:draggable="true"
						:sortable="true"
						:items="recSelesais"
						:cellHeight="180"
						:cellWidth="180"
						:rowCount="6"
						:gridWidth="200"
						@change="change"
						@remove="remove"
						
						@sort="sort"
						:style="'overflow-x: auto;height:' + ((screenHeight-90)/2-60) + 'px; '"
						>
						<template slot="cell" scope="props" >
							<div class="w3-round-large w3-padding-small warnaKotakSelesai" style="height: 170px; width: 170px;float: left!important;position: relative;">
								<div align="center" class="w3-xxxlarge w3-text-black">{{props.item.antrian_nomor}}</div>
								<div align="center" class="w3-xlarge w3-text-black">{{props.item.pelayanan_name}}</div>
								<div class="w3-center" style="position: absolute;bottom: 0px; padding: 5px; right: 0px; left: 0px;">
									<button style="width: 100%;" v-on:click="setPanggil(props.item, -1)" class="w3-btn w3-theme-d5 w3-small w3-round" type="button">Panggil Ulang</button>
									<!-- <button style="margin-left: 5px;" class="w3-btn w3-theme-d3 w3-small" type="button">selesai</button> -->
								</div>
							</div>
						</template>
					</grid>
				</div>
			</div>
			<div class="w3-col l3">
				<div class="w3-card-4 w3-round-large w3-padding w3-responsive" style="margin-left: 10px;" v-bind:style="'height:' + (screenHeight-90) + 'px; '">
					<button @click="setInit();loadTidakaktif();" type="button" class="w3-btn w3-block w3-blue"><i class="fa fa-refresh"></i> Reload</button>
					<table class="w3-table-all" style="margin-top: 10px;">
						<tbody>
							<tr v-for="(item,index) in recs" v-if="item.antrian_status='T'" :key="index">
								<td style="vertical-align: top;width: 15px;">{{item.antrian_nomor}}</td>
								<td>
									<template v-if="item.antrian_online=='T'">
										<div class="w3-row">
											<div class="w3-col" style="width: 75px;">NIK</div>
											<div class="w3-rest">: {{item.pasien_nik}}</div>
										</div>
										<div class="w3-row">
											<div class="w3-col" style="width: 75px;">Kunjungan</div>
											<div class="w3-rest">: {{item.pasien_nokunjungan}}</div>
										</div>
										<div class="w3-row">
											<div class="w3-col" style="width: 75px;">BPJS</div>
											<div class="w3-rest">: {{item.pasien_nobpjs}}</div>
										</div>
										<div class="w3-row">
											<div class="w3-col" style="width: 75px;">Nama</div>
											<div class="w3-rest">: {{item.pasien_name}}</div>
										</div>
										<div class="w3-row">
											<div class="w3-col" style="width: 75px;">Kelamin</div>
											<div class="w3-rest">: {{item.pasien_sex=='L' ? 'Laki - Laki' : 'Perempuan'}}</div>
										</div>
										<div class="w3-row">
											<div class="w3-col" style="width: 75px;">Alamat</div>
											<div class="w3-rest">
												<div style="float: left;width: 2px;">:</div>
												<div class="w3-rest" style="padding-left: 5px;">
												{{item.pasien_alamat}} RT {{item.pasien_rt}}, RW {{item.pasien_rw}},
												</div>
											</div>
											
										</div>
										<div class="w3-row">
											<div class="w3-col" style="width: 75px;">Kelurahan</div>
											<div class="w3-rest">: {{item.pasien_kelurahan}}</div>
										</div>
										<div class="w3-row">
											<div class="w3-col" style="width: 75px;">Kecamatan</div>
											<div class="w3-rest">: {{item.pasien_kecamatan}}</div>
										</div>
										<!-- <div class="w3-row">
											<div>RT/RW : {{item.pasien_rt}} / {{item.pasien_rw}}</div>
										</div> -->
									</template>
									<template v-else>
										<div>Ofline</div>
									</template>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
// import io from 'socket.io-client';

export default {
    head: {
        title: {
          inner: 'Antrian'
        },
    },
    name: 'Antrian',
    components: {},
    data() {
        return {
			// socket : io(`${this.apiUrl}`),
			screenWidth : 0,
            screenHeight : 0,
            recs: [],
			loading: {antrian:false},
			recSelesais: [],
			statusSuara: true
        };
    },
	sockets: {
		connection: function () {
			console.log('Koneksi socket')
		},
		get_antrian: function(data){
			console.log("data", data);
		},
		get_status_suara: function(data){
			this.statusSuara = data.status;
		},
		// get_antrian_mulai: function(data){
		// 	this.setInit();
		// },
		get_nomorbaru: function(data){
			console.log(data);
			// this.setInit();
			if (data.baru=='T'){
				const self = this;
				self.apiPost("/antrian/antrian/search", {
					operator: "AND",
					kondisi: "=",
					field: "antrian.antrian_code",
					fieldvalue: data.kode,
					limit: 1,
					offset: 0,
				}, function(res){
					self.recs.push(res.rec[0]);
				})
			}else{
				this.recSelesais.push(data.rec);
				this.$delete(this.recs, data.kode);
			}
		}
	},
    methods : {
        click ({ items, index }) {
		  let value = items.find(v => v.index === index)
		  this.selected = value.item
		  console.log(this.selected)
		},
		change (event) {
		  console.log('change', event)
		},
		remove (event) {
		  console.log('remove', event)
		},
		sort (event) {
		  console.log('sort', event)
		},
		setInit() {
			const self = this;
			self.showLoading("load absensi ..");
			self.apiPost("/antrian/antrian/search", {
				operator: "AND",
				kondisi: "=;=",
				field: "antrian_date;antrian_status",
				fieldvalue: self.getNow('ed') + ";T",
				limit: 500,
				offset: 0,
				order: 'antrian_urut'
			}, function(res){
				if(res.success){
					self.recs = res.rec;
				}
				self.hideLoading();
			}, function(err){
				console.log(err);
				self.hideLoading();
			});
		},
		loadTidakaktif(){
			const self = this;
			self.showLoading("load absensi ..");
			self.apiPost("/antrian/antrian/search", {
				operator: "AND",
				kondisi: "=;=",
				field: "antrian_date;antrian_status",
				fieldvalue: self.getNow('ed') + ";C",
				limit: 500,
				offset: 0,
				order: 'antrian_urut'
			}, function(res){
				if(res.success){
					self.recSelesais = res.rec;
				}
				self.hideLoading();
			}, function(err){
				console.log(err);
				self.hideLoading();
			});
		},
		setPanggil(dtl, index){
			if(this.statusSuara==false){
				this.statusSuara = true;
				this.$socket.emit('send_antrian', dtl);
				if(index!=-1){
					// this.recSelesais.push(dtl);
					// this.$delete(this.recs,index);
					this.$socket.emit("send_nomorbaru",{"baru": 'F', "kode": index, "rec": dtl });
				}
			}else{
				this.$toast.error("Pemanggilan sedang berlangsung ..");
			}
		}
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        },
    },
    filters: {
        toRp (value) {
            return `${Intl.NumberFormat('de-DE').format(value)}`
        }
    },
    watch: {
        '$route': function(baru, lama){
			if(baru.name=='Antrian'){
				this.setInit();
			}
        }
    },
    mounted(){
		const self = this;
		// self.$socket.emit('send_antrian', {});
		self.screenWidth = window.innerWidth- 250;
        self.screenHeight = window.innerHeight;
		self.setInit();
		self.loadTidakaktif();
		this.$socket.emit('send_antrian_selesai', {status:false});
		console.log(this.$socket);
		// self.$socket.on("get_antrian", function(data) {
		// 	console.log("This event was fired by eg. sio.emit('kebab-case')", data)
		// })
    }
};
</script>